export default {
  methods: {
    mutateCode(item) {
      let locale = this.$i18n.locale.toLowerCase();
      let responseObject = {};
      let locales = ["ru", "en"];
      let keys = ["city", "code", "country", "name"];
      for (let i = 0; i < locales.length; i++) {
        keys.forEach(it => {
          if (item[locales[i]][it]) {
            responseObject[it] = item[locales[i]][it];
          }
        });
      }
      keys.forEach(it => {
        if (item[locale][it]) {
          responseObject[it] = item[locale][it];
        }
      });

      return responseObject;
    },
    mutateKeyLang(item, key) {
      return (
        item[key + "_" + this.$i18n.locale.toUpperCase()] ||
        item[key + this.$i18n.locale.toUpperCase()]
      );
    }
  }
};
